import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Top',
    component: () => import('@/views/Top.vue'),
    meta: { title: 'PC-COMBO PCパーツの関連情報まとめサイト', description: 'PCパーツの関連情報まとめサイトです。'}
  },
  {
    path: '/category',
    name: 'Category',
    component: () => import('@/views/Category.vue'),
    meta: { title: 'カテゴリ一覧'}
  },
  {
    path: '/category/:categorySlug',
    name: 'Manufacturer',
    component: () => import('@/views/Manufacturer.vue'),
    meta: { title: '製造メーカー一覧'}
  },
  {
    path: '/category/:categorySlug/:manufacturerSlug',
    name: 'Brand',
    component: () => import('@/views/Brand.vue'),
    meta: { title: 'ブランド一覧'}
  },
  {
    path: '/category/:categorySlug/:manufacturerSlug/:brandSlug',
    name: 'ProductList',
    component: () => import('@/views/ProductList.vue'),
    meta: { title: '製品一覧'}
  },
  {
    path: '/product/:productId(\\d+)',
    name: 'Product',
    component: () => import('@/views/Product.vue'),
    meta: { title: '製品詳細と関連情報'}
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('@/views/About.vue'),
    meta: { title: '当サイトについて'}
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
