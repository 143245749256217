<template>
  <v-app>
    <v-app-bar
      app
      color="orange"
      dark
    >
      <v-app-bar-title>PC-COMBO.TOKYO PCパーツの関連情報ポータルサイト</v-app-bar-title>
    </v-app-bar>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'App',
  methods: {
    createTitleDesc : function(routeInstance){
      if(routeInstance.meta.title){
        let setTitle = routeInstance.meta.title;
        document.title = setTitle + ' | PC-COMBO';
      } else {
        document.title = 'その他 | PC-COMBO';
      }

      let description = 'PC-COMBOです。';
      if(routeInstance.meta.description){
        description = routeInstance.meta.description;
      }
      document.querySelector("meta[name='description']").setAttribute('content', description);
    }
  },
  watch: {
    '$route' (routeInstance) {
      this.createTitleDesc(routeInstance);
    }
  },
  mounted() {
    let routeInstance = this.$route;
    this.createTitleDesc(routeInstance);
  }
};
</script>
